import React from "react";
import {render, unmountComponentAtNode} from "react-dom";
import {bindable, customElement, inject, LogManager, noView} from 'aurelia-framework';
import {DateRangePicker} from "rsuite";
import {transformModelDateToComponentDate} from "./transformer";

import 'rsuite/dist/rsuite.css';
import './date-range-input.less';
import moment from "moment-timezone";


const locale = {
    monday: 'Mo',
    tuesday: 'Di',
    wednesday: 'Mi',
    thursday: 'Do',
    friday: 'Fr',
    saturday: 'Sa',
    sunday: 'So',
    ok: 'OK',
    today: 'heute',
    yesterday: 'gestern',
    last7Days: 'Letzte Woche',
    formattedMonthPattern: "MMM yyyy",
    formattedDayPattern: "d.M.yyyy",
};

Date.prototype.toPlainISO = function () {
    return this.toISOString().substr(0, 19);
};

@noView()
@customElement('sio-date-range-input')
@inject(Element)
export class DateRangeInput {

    @bindable({defaultBindingMode: 2}) modelDate;

    @bindable({defaultBindingMode: 2}) fromDate;
    @bindable({defaultBindingMode: 2}) toDate;
    @bindable options = {};

    constructor(element) {
        this.element = element;
        this.onChange = value => {
            this.modelDate = value?.map(v => !v ? undefined : moment(v).local().format("YYYY-MM-DD")) || [];
            this.fromDate = this.modelDate[0];
            this.toDate = this.modelDate[1];
        }
        this.renderValue = value => !value ? null : value.map(v => moment(v).local().format("D.M.YY")).join(' – ');
    }

    detached() {
        unmountComponentAtNode(this.element);
    }

    modelDateChanged() {
        this.render();
    }

    fromDateChanged() {
        this.modelDate = [this.fromDate, this.modelDate?.[1] ?? undefined];
    }

    toDateChanged() {
        this.modelDate = [this.modelDate?.[0] ?? undefined, this.toDate];
    }

    optionsChanged() {
        this.render();
    }

    render() {

        const value = (this.modelDate || [this.fromDate, this.toDate]).map(v => !v ? undefined :
            new Date(transformModelDateToComponentDate(v, "YYYY-MM-DD", this.options).date)
        );

        let ranges = [];

        for (let range of this.options?.ranges ?? []) {

            if (typeof range.value[0] === 'string') {
                range.value[0] = moment(range.value[0]).toDate();
            }

            if (typeof range.value[1] === 'string') {
                range.value[1] = moment(range.value[1]).toDate();
            }

            ranges.push(range);
        }

        if (ranges.length === 0) {
            ranges.push({
                label: 'Dieser Monat',
                value: [
                    moment().startOf('month').toDate(),
                    moment().endOf('month').toDate(),
                ]
            });

            ranges.push({
                label: 'Dieses Jahr',
                value: [
                    moment().startOf('year').toDate(),
                    moment().endOf('year').toDate(),
                ]
            });

            ranges.push({
                label: 'Nächstes Jahr',
                value: [
                    moment().add(1, 'year').startOf('year').toDate(),
                    moment().add(1, 'year').endOf('year').toDate(),
                ]
            });
        }

        render(
            <div className="form-control">
                <DateRangePicker
                    block
                    isoWeek={true}
                    format={'dd.MM.yy'}
                    // disabledDate={DateRangePicker.afterToday()}
                    locale={locale}
                    ranges={ranges}
                    value={value}
                    renderValue={this.renderValue}
                    character={' - '}
                    placeholder="&nbsp;" // need this to prevent showing ugly "YYYY-MM-DD ~ YYYY-MM-DD" placeholder
                    onChange={this.onChange}
                />
            </div>
            ,
            this.element
        )
    }
}
