import {inject} from "aurelia-framework";
import {DialogController} from 'aurelia-dialog';
import {Client} from "../api/client";

import "./documents-preview-dialog.less";
import {ConfigurationLoader} from "../form/loader/configuration-loader";
import * as _ from "lodash";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {FlashService} from "../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import printJS from "print-js";
import {UserClient} from "../api/user-client";
import {BindingSignaler} from "aurelia-templating-resources";

@inject(
    Client,
    EventAggregator,
    FlashService,
    ConfigurationLoader,
    FormServiceFactory,
    DialogController,
    UserClient,
    BindingSignaler
)
export class DocumentsPreviewDialog {

    documents = [];
    selectedTab = 'documents';
    loading = false;

    objects = [];
    formData = [];
    formErrors = [];
    currentObject;
    currentIndex;

    errors = [];
    warnings = [];

    presets = {
        regular: {
            title: 'sio.documents_preview.title',
            tabs: [
                'email',
                'documents'
            ],
            active: 'documents',
            previewActions: [
                'email',
                'save',
                'print'
            ],
            saveLabel: 'document-generator.save',
            previousEmailLabel: 'document-generator.email-again',
            emailLabel: 'document-generator.email',
            previousPrintLabel: 'document-generator.print-again',
            printLabel: 'document-generator.print',
            emailsTab: 'E-Mail',
            documentsTab: 'Dokumente'
        },
        resend: {
            title: 'email.resend-email',
            tabs: [
                'email',
                'documents',
            ],
            active: 'email',
            previewActions: [
                'email',
            ],
            emailLabel: 'email.resend-email',
            emailsTab: 'E-Mail',
            documentsTab: 'Anhänge'
        },
        reply: {
            title: 'email.reply-email',
            tabs: [
                'email',
            ],
            active: 'email',
            previewActions: [
                'email',
            ],
            emailLabel: 'email.reply-email',
            emailsTab: 'E-Mail',
            documentsTab: 'Dokumente'
        },
        forward: {
            title: 'email.forward-email',
            tabs: [
                'email',
            ],
            active: 'email',
            previewActions: [
                'email',
            ],
            emailLabel: 'email.forward-email',
            emailsTab: 'E-Mail',
            documentsTab: 'Dokumente'
        },
        email: {
            title: 'email.send-email',
            tabs: [
                'email'
            ],
            active: 'email',
            previewActions: [
                'email',
            ],
            emailLabel: 'email.send-email',
            emailsTab: 'E-Mail',
            documentsTab: 'Dokumente'
        },
        letter: {
            title: 'document-generator.letter',
            tabs: [
                'email',
                'documents'
            ],
            active: 'email',
            previewActions: [
                'print',
            ],
            printLabel: 'document-generator.send-letter',
            emailsTab: 'Brief',
            documentsTab: 'Vorschau'
        },
        document: {
            title: 'document-generator.document',
            tabs: [
                'documents'
            ],
            active: 'documents',
            previewActions: [
                'print',
            ],
            printLabel: 'document-generator.print-document',
            emailsTab: 'E-Mail',
            documentsTab: 'Vorschau'
        },
        registrationList: {
            title: 'email.send-email',
            tabs: [
                'email',
                'documents',
            ],
            active: 'email',
            previewActions: [
                'email',
            ],
            emailLabel: 'Meldeliste erstellen und per E-Mail versenden',
            emailsTab: 'E-Mail',
            documentsTab: 'Dokumente'
        },
        voucher: {
            title: 'sio.documents_preview.title',
            tabs: [
                'email',
                'documents'
            ],
            active: 'email',
            previewActions: [
                'email'
            ],
            saveLabel: 'document-generator.save',
            previousEmailLabel: 'document-generator.email-again',
            emailLabel: 'document-generator.email',
            previousPrintLabel: 'document-generator.print-again',
            printLabel: 'document-generator.print',
            emailsTab: 'E-Mail',
            documentsTab: 'Dokumente'
        },
    };

    //  AI related code: temporarily commented out

    // lastArtificialIntelligenceResponse = null;
    //
    // isShowLastArtificialIntelligenceResponse = false;
    //
    // toggleShowLastArtificialIntelligenceResponses() {
    //     this.isShowLastArtificialIntelligenceResponse = !this.isShowLastArtificialIntelligenceResponse;
    // }
    //
    // getLastArtificialIntelligenceResponse() {
    //     this.lastArtificialIntelligenceResponse = this.artificialIntelligence.getLastResponse();
    //
    //     return this.lastArtificialIntelligenceResponse;
    // }
    //
    // async artificialIntelligenceDialog(context)
    // {
    //     let ctx = {
    //         id: context.id,
    //         modelId: context.modelId,
    //     }
    //
    //     try {
    //         await this.artificialIntelligence.generate(ctx);
    //     } catch (error) {
    //         console.error(error);
    //
    //         this.flashService.error(error);
    //     }
    //
    //     this.lastArtificialIntelligenceResponse = this.getLastArtificialIntelligenceResponse();
    // }
    //
    // isArtificialIntelligenceEnabled() {
    //     let enabledContexts = this.artificialIntelligence?.documentGenerateEnabledContexts;
    //
    //     return enabledContexts?.includes(this.context?.previewGeneratorId) ?? false;
    // }

    // ./AI part

    closeDialog = () => {
        this.dialogController.ok();
    }

    constructor(
        client,
        ea,
        flashService,
        configurationLoader,
        formServiceFactory,
        dialogController,
        userClient,
        signaler
    ) {
        this.client = client;
        this.ea = ea;
        this.flashService = flashService;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;
        this.dialogController = dialogController;
        this.userClient = userClient;
        this.signaler = signaler;

        this.onPartialLoad = this.partialLoad.bind(this);
    }

    async activate(context) {

        console.log('Context', _.cloneDeep(context));

        let user = this.userClient.getUser();

        this.dialogController.settings.overlayDismiss = user?.settings?.userSettings?.closeDialogsWhenClickingOutside ?? false;

        let showSaveOption = user?.instance?.settings?.instanceGeneral?.disableSaveOption ?? false;

        this.preset = context.previewPreset || 'regular';
        this.settings = this.presets[this.preset];

        if (showSaveOption && this.settings.previewActions.indexOf('save') > -1) {
            this.settings.previewActions.splice(this.settings.previewActions.indexOf('save'), 1);
        }

        this.selectedTab = this.settings.active;

        //Preselect form case
        if (context?.additionalData?.objects) {
            this.objects = [];

            for (let objectId of context?.additionalData?.objects) {
                let splits = objectId.split('-');

                let id = splits.pop();
                let modelId = splits.join('-');

                this.objects.push({
                    id: id,
                    modelId: modelId,
                });
            }
            delete context?.additionalData?.objects;

            //Bulk workflow case
        } else if (typeof context.id !== 'string') {
            this.objects = [];

            for (let objectId of context.id) {
                this.objects.push({
                    id: objectId,
                    modelId: context.modelId,
                });
            }

        } else {
            this.objects = [{
                id: context.id,
                modelId: context.modelId,
            }];
        }

        this.context = context;
        this.title = context.title || this.settings.title;

        await this.selectObject(0, true);
    }

    async selectObject(index, initial) {

        if (this.formService) {
            this.formData[this.currentIndex] = this.formService.getValue();
        }

        this.currentObject = this.objects[index];
        this.currentIndex = index;
        this.documents = [];

        let data = Object.assign(
            {
                object: this.currentObject,
                type: this.context.previewGeneratorId,
                previousVersion: this.context.previewPrevious,
            }, this.context.additionalData || {});

        let config = await this.configurationLoader.get('document-generator/preview-options', data);

        this.formService = this.formServiceFactory.getFormService(config, _.cloneDeep(data), this.currentObject);
        this.formService.changeCallback = this.formValueChanged.bind(this);
        let secondCall = false;

        if (this.formData[index] || this.context?.additionalData?.customEmail) {
            this.formService.setValue(this.formData[index]);
            secondCall = true;
        }

        if (this.formErrors[index]) {
            this.formService.setErrors(this.formErrors[index]);
        }

        await this.preview(initial, secondCall);
    }

    selectTab(tab) {
        this.selectedTab = tab;
    }

    getFields(formService) {
        let fields = [];

        for (let field of formService.config.fields) {
            if (field.property === 'emailSubject' || field.property === 'emailContent' || field.hidden === true) {
                continue;
            }

            if (field.property === 'printerSelection' && this.settings.previewActions.indexOf('print') === -1) {
                continue;
            }

            if (field.property === 'emailReceiver' && this.settings.previewActions.indexOf('email') === -1) {
                continue;
            }

            if (field.property === 'senderSelection' && this.settings.previewActions.indexOf('email') === -1) {
                continue;
            }

            fields.push(field);
        }

        return fields;
    }

    reloadConfig() {
        let currentFormValue = this.formService.getValue();

        this.configurationLoader.get('document-generator/preview-options', currentFormValue).then((config) => {

            _.forEach(this.formService.config.fields, oldField => {
                const newField = _.find(config.fields, (field) => field.property === oldField.property);

                if (!newField) {
                    return true;
                }

                const oldDefault = (oldField.default != null ? oldField.default : null);
                const newDefault = (newField.default != null ? newField.default : null);

                const defaultValueInFieldConfigChanged = !_.isEqual(oldDefault, newDefault);
                const userMadeChanges = !_.isEqualWith(
                    oldDefault,
                    currentFormValue[oldField.property],
                    (defVal, curVal) => {
                        const comparisonFunc = (defVal, curVal) => {
                            if (!_.isObject(curVal) || !_.isObject(defVal)) {
                                return curVal == defVal;
                            }

                            return !_.some(curVal, (value, key) => {
                                return !comparisonFunc(defVal[key], curVal[key]);
                            });
                        };

                        return comparisonFunc(defVal, curVal);
                    }
                );

                if (
                    defaultValueInFieldConfigChanged &&
                    !userMadeChanges
                ) {
                    currentFormValue[oldField.property] = _.cloneDeep(newDefault);
                }
            });

            this.formService.setConfig(config, currentFormValue, this.currentObject);

            this.signaler.signal('sio_document_generator_reloaded');
        });
    }

    formValueChanged(field) {

        if (field.property === 'template') {
            this.reloadConfig();
        }

        if (this.preset === 'letter' && (field.property === 'emailSubject' || field.property === 'emailContent')) {
            this.showPreview = true;
        }

        if (field.options && field.options.preview) {
            this.showPreview = true;
        }
    }

    async partialLoad(configurationIndex, contextIndex) {
        let data = (await this.client.patch(
            'document-generation',
            [
                Object.assign({}, this.formService.getValue(), {action: 'preview', configurationIndex, contextIndex})
            ]
        )).data;

        return data[0].documents?.[0];
    }

    async preview(initial, secondCall = false) {
        this.loading = true;
        this.showPreview = false;
        this.errors = [];
        this.warnings = [];

        try {
            let data = (await this.client.patch(
                'document-generation',
                [
                    Object.assign({}, this.formService.getValue(), {action: 'preview'})
                ]
            )).data;

            this.documents = data[0].documents;
            this.errors = data[0].errors ?? [];
            this.warnings = data[0].warnings ?? [];

            let value = this.formService.getValue();

            if (this.preset !== 'letter' && (secondCall === false || value.emailContent == '' || value.emailContent == null)) {
                value.emailContent = data[0].emailContent;
            }

            if (this.preset !== 'letter' && (secondCall === false || value.emailSubject == '' || value.emailSubject == null)) {
                value.emailSubject = data[0].emailSubject;
            }

            this.formService.setValue(value);

        } catch (err) {
            this.handleErrors(err, initial);
        } finally {
            this.loading = false;
        }
    }

    async send(action) {

        this.submitting = true;

        this.formData[this.currentIndex] = this.formService.getValue();

        let requests = [];

        for (let i = 0; i < this.objects.length; i++) {

            const currentObject = this.objects[i];
            let currentFormData = this.formData[i];

            if (!currentFormData) {
                let data = Object.assign(
                    {
                        object: currentObject,
                        type: this.context.previewGeneratorId,
                        previousVersion: this.context.previewPrevious,
                    }, this.context.additionalData || {});

                const config = await this.configurationLoader.get('document-generator/preview-options', data);

                const formService = this.formServiceFactory.getFormService(config, _.cloneDeep(data), currentObject);

                this.formData[i] = formService.getValue();


            }

            requests.push(Object.assign({
                    object: currentObject,
                    type: this.context.previewGeneratorId,
                    previousVersion: this.context.previewPrevious
                },
                this.formData[i] || {},
                {action: action, backgroundTrigger: true,}));
        }

        try {
            let data = (await this.client.patch(
                'document-generation',
                requests
            )).data;

            if (action === 'print' && data?.[0]?.url) {
                //Todo multiple documents for printing
                printJS({printable: data[0].url, type: 'pdf'});

                this.flashService.success('document-generator.finished');
            } else {
                this.flashService.success('document-generator.running_in_background');
            }

            this.closeDialog();

            ['file/file', 'payment/payment'].forEach(modelId => {
                this.ea.publish('sio_form_post_submit', {config: {modelId}});
            });

        } catch (err) {
            console.log('ERR', err);

            this.handleErrors(err);
        } finally {
            this.submitting = false;
        }
    }

    hasErrors(errors) {
        if (!errors || !errors.children) {
            return false;
        }

        let hasErrors = false;

        _.forEach(errors.children, (element, key) => {
            if (element.errors && element.errors.length > 0) {
                hasErrors = true;
            }
        });

        return hasErrors;
    }

    handleErrors(err, initial) {
        if (err?.data?.errors) {

            this.formErrors = err.data.errors;

            if (this.formErrors[this.currentIndex]) {
                this.formService.resetErrors(true);
                this.formService.setErrors(this.formErrors[this.currentIndex]);
            }
        }

        let message = null;

        if (err?.data?.localizedMessage) {
            message = err.data.localizedMessage;
        } else if (403 === err.status) {
            message = 'sio.access_denied';
        } else if (400 !== err.status) {
            message = this.formService.rootConfig.labels.communicationError;
        } else {
            message = this.formService.rootConfig.labels.validationError;
        }

        if (message) {
            if (initial) {
                throw {data: {message: message}};
            } else {
                this.flashService.error(message);
            }
        }
    }
}
