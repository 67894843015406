import {computedFrom, LogManager, observable} from 'aurelia-framework';
import * as _ from "lodash";
import {EditableTable} from "../../widgets/form-elements/editable-table/editable-table";
import {PLATFORM} from "aurelia-pal";

const logger = LogManager.getLogger('FormField');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

const TYPES = Object.freeze({
    address: PLATFORM.moduleName('form/element/address-element.html'),
    'annotation-viewer': PLATFORM.moduleName('form/element/annotation-viewer-element.html'),
    'address-selector': PLATFORM.moduleName('form/element/address-selector-element.html'),
    checkbox: PLATFORM.moduleName('form/element/checkbox-element.html'),
    choice: PLATFORM.moduleName('form/element/choice-element.html'),
    color: PLATFORM.moduleName('form/element/color-element.html'),
    'color-palette': PLATFORM.moduleName('form/element/color-palette-element.html'),
    'cost-report-assignments': PLATFORM.moduleName('form/element/cost-report-assignments-element.html'),
    date: PLATFORM.moduleName('form/element/date-element.html'),
    'date-range': PLATFORM.moduleName('form/element/date-range-element.html'),
    editor: PLATFORM.moduleName('form/element/editor-element.html'),
    'editable-table': PLATFORM.moduleName('form/element/editable-table-element.html'),
    file: PLATFORM.moduleName('form/element/file-element.html'),
    hash: PLATFORM.moduleName('form/element/empty-element.html'),
    heading: PLATFORM.moduleName('form/element/heading-element.html'),
    html: PLATFORM.moduleName('form/element/html-element.html'),
    identifier: PLATFORM.moduleName('form/element/input-element.html'),
    input: PLATFORM.moduleName('form/element/input-element.html'),
    secret: PLATFORM.moduleName('form/element/input-element.html'),
    money: PLATFORM.moduleName('form/element/money-element.html'),
    number: PLATFORM.moduleName('form/element/number-element.html'),
    'filter-condition': PLATFORM.moduleName('form/element/filter-condition-element.html'),
    'object-condition': PLATFORM.moduleName('form/element/object-condition-element.html'),
    phone: PLATFORM.moduleName('form/element/phone-element.html'),
    'price-set': PLATFORM.moduleName('form/element/price-set-element.html'),
    'contingent-set': PLATFORM.moduleName('form/element/contingent-set-element.html'),
    'stop-sale-set': PLATFORM.moduleName('form/element/stop-sale-set-element.html'),
    tags: PLATFORM.moduleName('form/element/tags-element.html'),
    translatable: PLATFORM.moduleName('form/element/translatable-element.html'),
    'translatable-image': PLATFORM.moduleName('form/element/translatable-image-element.html'),
    time: PLATFORM.moduleName('form/element/time-element.html'),
    'time-new': PLATFORM.moduleName('form/element/time-new-element.html'),
    'range-slider': PLATFORM.moduleName('form/element/range-slider-element.html'),
    'uploaded-file': PLATFORM.moduleName('form/element/uploaded-file-element.html'),
    'video-reference': PLATFORM.moduleName('form/element/video-reference-element.html')
});

export class FormField {
    @observable value = null;

    viewModel = PLATFORM.moduleName('form/element/form-element');

    formService;
    parent;
    type;

    errors = [];
    childErrors = {};

    onPostError; // Callback that happens after errors array is updated

    constructor(formService, config, path, value = null, parent = null) {
        this.setConfig(config, value);

        this.fullProperty = path;
        this.formService = formService;
        this.parent = parent;
    }

    setConfig(config, value) {
        Object.assign(this, config);

        this.view = this._getView();
        this.setValue(value);
    }

    @computedFrom('errors')
    get valid() {
        if (Array.isArray(this.childErrors)) {
            return 0 === this.errors.length && _.isEmpty(this.childErrors);
        } else if (typeof this.childErrors === "object") {
            for (const key in this.childErrors) {
                if (!_.isEmpty(this.childErrors[key])) {
                    return false;
                }
            }
            return 0 === this.errors.length;
        } else {
            return 0 === this.errors.length;
        }
    }

    resetErrors(silent = false) {
        this.setErrors({errors: []}, silent);
    }

    setErrors(errors, silent = false) {
        logger.debug('Set errors', this, errors);

        this.errors = errors.errors && errors.errors.length > 0 ? errors.errors : [];
        this.childErrors = errors.children ? errors.children : {};

        if (!silent && this.onPostError) {
            this.onPostError(this);
        }
    }

    resetValue() {
        this.value = null;
    }

    setValue(value) {
        this.modelChange = true;

        if (this.type === 'editable-table') {
            this.value = EditableTable.normalizeCore2InitialValue(value);
        } else {
            this.value = value;
        }

        this.modelChange = false;
    }

    getValue() {
        return this.value;
    }

    valueChanged() {
        this.resetErrors();

        if (this.modelChange) {
            //Todo validity
            return;
        }

        //Todo validity

        logger.debug('Form field value changed', this.fullProperty, this.value);

        this.formService.change(this);
    }

    _getView() {
        const type = 'text' === this.type ? 'input' : this.type;

        if (!TYPES.hasOwnProperty(type)) {
            throw new Error('Unknown form field type: ' + type);
        }

        return TYPES[type];
    }
}
